<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Štítky - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>
        <a-form-item
          :validate-status="error('label') ? 'error' : ''"
          :help="error('label') || ''"
          label="Označení"
        >
          <a-input placeholder="Označení" v-decorator="['label', {rules: [{max: 25, message: 'Označení nemůže být delší než 25 znaků!'}, {required: true, message: 'Označení musí být vyplněno!'}]}]"/>
        </a-form-item>

        <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>
        <a-form-item
          label="Barva pozadí"
        >
          <a-radio-group name="radioGroup" v-decorator="['color', { rules: [{ required: true, message: 'Barva pozadí musí být vyplněna!' }] }]">
            <a-radio :style="radioStyle" :key="index" :value="color.hex" v-for="(color, index) in colors">{{ color.name }} | {{ color.hex }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          label="Náhled"
        >
          <div :style="'line-height:1.7;float:left;background-color:' + (this.form.getFieldValue('color')) + ';color: #fff !important;' +
           'padding: 0 10px;font-size: 11px !important;margin-bottom: 5px;text-transform: uppercase;display: block;' +
            'border-radius: 4px;-webkit-border-radius: 4px;-moz-border-radius: 4px;z-index: 1;'">{{(this.form.getFieldValue('label'))}}</div>
        </a-form-item>
        <a-form-item
          label="HEX"
        >
          <p>{{ detail.color }}</p>
        </a-form-item>
        <a-form-item
          label="Pozice"
        >
          <p>{{ detail.position }}</p>
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'

export default {
  components: { ActionTools, LanguageTab },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data: function () {
    return {
      hasErrors,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      id: this.$route.params.id,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/sticker',
          title: 'Seznam',
        },
      ],
      item: {
        ser_id: null,
        color: null,
        languages: [],
      },
      loaded: [],
    }
  },
  computed: {
    language: function() {
      return this.$store.getters['language/active']
    },
    currentLanguage: function() {
      return this.$store.getters['sticker/currentLanguageDetail']
    },
    detail: function() {
      return this.$store.getters['sticker/getDetail']
    },
    colors: function() {
      return this.$store.getters['sticker/colors']
    },
  },
  methods: {
    initDetail() {
      this.loading = true
      this.$store.dispatch('sticker/getOne', this.id)
        .then(() => {
          this.item = Object.assign({}, this.detail)
          const currentDetailLang = this.$store.getters['sticker/currentLanguageDetail']
          this.form.setFieldsValue({
            label: currentDetailLang.label,
            color: this.detail.color,
          })
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.loaded.push(this.language)
        })
    },
    getData() {
      return {
        color: this.form.getFieldValue('color') === undefined ? null : this.form.getFieldValue('color'),
        languages: this.item.languages.filter(x => x.label !== undefined && x.label.trim().length > 0),
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('sticker/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleChange(oldLanguage) {
      const foundObj = { item: false }
      const newObj = {
        item: {
          lge_id: oldLanguage,
          label: this.form.getFieldValue('label'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.item
          foundObj.item = true
          return true
        }
      })
      if (!foundObj.item) {
        this.item.languages.push(newObj.item)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
      this.item = {
        ser_id: null,
        color: null,
        languages: [],
      }
      this.loaded = []
    },
  },
  created() {
    if (this.language !== null && !this.loaded.includes(this.language)) {
      this.initDetail()
    }
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initDetail()
      }
    },
    language(newValue, oldValue) {
      if (oldValue !== null && this.loaded.includes(oldValue)) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        if (!this.loaded.includes(this.language)) {
          this.initDetail()
        } else {
          const foundObj = { item: this.item.languages.find(x => x.lge_id === newValue) }
          let newObj
          if (foundObj.item === undefined) {
            newObj = {
              label: undefined,
            }
          } else {
            newObj = {
              label: foundObj.item.label,
            }
          }
          this.form.setFieldsValue(newObj)
          if (!this.loaded.includes(this.language)) {
            this.initDetail()
          }
          this.form.setFieldsValue(newObj)
        }
        this.form.validateFields()
      }
    },
  },
}
</script>
<style lang="scss" module>
</style>
